// card is the element that wraps content
.card {
  margin: 0 auto 100px; // the property from Section.js
  transition: transform 30ms linear, filter 300ms, margin-top 700ms ease,
    opacity 400ms ease;
  opacity: 1;
  background-color: white;
  //max-width: 1060px;
  box-shadow: 0px -2px 4px rgba(50, 50, 50, 0.1);
  position: relative;
  top: 0;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
  }

  img.float {
    border-radius: 3px;
    max-width: 275px;
    float: left;
    width: 40%;
    margin: 9% 30px 20px 9%;
  }

  img + img {
    border-top: #848484 solid 75px;
  }

  p {
    font-size: calc(13px + 0.5vw);
    margin: 20px 9%;
  }

  p:first-of-type {
    margin-top: 7% !important;
  }

  p:last-of-type {
    margin-bottom: 7% !important;
  }

  .browser-screenshot-wrapper {
    padding-top: 15px;
  }
}

.card.negative {
  background-color: #383838;
  p {
    color: white;
  }
}

.card.static {
  position: fixed;
  top: 175px;
}

#about-card {
  .float {
    margin: 9% 30px 20px 9%;
    transform: rotate(-0.5deg);
  }
}

// these card class types are for each position in the stack
// no transform, full opacity, not nested in cardWrapper
.cardFront {
}

.cardMid {
  position: fixed;
  z-index: -10;
  opacity: 0.7;
  top: 140px;
}

.cardBack {
  opacity: 0.5;
}

.unloaded {
  // load transition class
  margin-top: 20px;
  opacity: 0 !important;
}

// BREAKPOINT DEPENDENT STYLES
// large
@media ($largeBreakRule) {
  .card {
    border-radius: 3px;
  }
}

// medium
@media ($medBreakRule) {
}

// small
@media ($smallBreakRule) {
  .card {
    border-radius: 10px;
    img.float {
      display: none;
    }
  }
  #about-card {
    img.float {
      margin: 10% auto -1% auto;
      width: 100%;
      transform: rotate(0);
      display: block;
      float: none;
    }
  }
}
