#about-card {
  h3 {
    padding: 9%;
    line-height: 180%;
    letter-spacing: 0;
    font-size: 1.4em;
    font-family: "ubuntu", sans-serif;
  }

  h3 + p {
  }

  .skillsContainer {
    padding: 25px 0 60px 0;
    text-align: center;
    //background: url("../images/script-bg.jpg");
    background: linear-gradient(90deg, #e2e2e2, #c5c5c5);
    background-color: #e2e2e2;

    h3 {
      color: white;
      padding: 0;
    }

    .iconContainer {
      text-align: initial;
      margin-top: 20px;
      padding: 0 9% 0 9%;
      // flexbox support for firefox, chrome, and safari
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      justify-content: center;
      -webkit-justify-content: center;

      .icon {
        transition: all 300ms ease;
        position: relative;
        height: 140px;
        width: 140px;
        background-color: white;
        margin: 0 4px 8px 4px;
        border-radius: 5px;
      }

      .icon:hover {
        margin-top: -5px;
        margin-bottom: 3px;
        height: 150px;
        width: 150px;
      }

      img {
        position: relative;
        width: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .adobeCert {
    height: 75px;
    padding: 45px 20px;
    background-color: #262626;

    .container {
      max-width: 500px;
      display: block;
      margin: 0 auto;
      padding: 0 15px;
    }

    p {
      float: left;
      color: white;
      margin-top: 20px;
      font-size: 1.2em;
      padding: 0;

      span {
        color: #9f95ff;
      }
    }

    img {
      float: right;
      width: 70px;
    }
  }

  .connectLink {
    text-align: center;
    overflow: auto;

    // todo fix this messiness
    a {
      display: inline-block;
      transition: color 350ms;
      padding: 0;
      margin: 20px 0 20px 0;
      text-decoration: none;
      font-family: "ubuntu", sans-serif;
      color: $textGrey;
      line-height: 180%;
      font-size: 0.91em;
    }

    a:hover {
      color: #d1d1d1;
    }
  }
}

@media ($medBreakRule) {
  #about-card {
    h3 {
      font-size: 1.3em;
    }

    .adobeCert .container {
      text-align: center;

      p {
        float: none;
      }

      img {
        display: none;
      }
    }
  }
}

@media ($smallBreakRule) {
  #about-card h3 {
    font-size: 1.1em;
  }

  #about-card .skillsContainer .iconContainer .icon {
    height: 80px;
    width: 80px;
  }
}
