// BREAKPOINT DEPENDENT STYLES AT BOTTOM
.main-wrapper {
  position: relative;
  padding: 175px 3% 0 3%;
  overflow: hidden;

  .titleContainer {
    z-index: -100;
    top: 70px;
    position: fixed;

    h2 {
      font-size: 3em;
      color: white;
      letter-spacing: 9px;
    }
  }

  .italic {
    font-style: italic;
  }
}

.sectionContainer {
  transition: opacity 500ms ease;
  opacity: 1;
}

.section-bg-gradient {
	position: fixed;
  top: 0;
  margin-left: -3%;
  width: 100%;
  transition: opacity 700ms, background-color 500ms ease;
  height: 100%;
  z-index: -1000; // otherwise it covers the nav, and other things that are fixed/absolutely positioned
}

.section-bg-gradient.inactive {
	opacity: 0;
}

.inactive {
  opacity: 0;
}

// BREAKPOINT DEPENDENT STYLES
// large
@media ($largeBreakRule) {
  .main-wrapper {
    margin-left: 275px;
  }
}

// medium
@media ($medBreakRule) {
  .main-wrapper {
    margin-left: 150px;
  }
}

// small
@media ($smallBreakRule) {
  .main-wrapper {
    margin-left: 0;

    .titleContainer {
      margin-top: 30px;
			width: 97%;
      text-align: center;
      height: 50px;
      //margin-top: 20px;
      //margin-left: 40px;

      h2 {
        font-size: 1.75em;
        color: white;
        letter-spacing: 9px;
        //position: fixed;
      }
    }
  }

  .main-wrapper::before {
    opacity: 0.6; // this works b/c the body has a black background
  }
}
