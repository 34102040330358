#designSection {
	img {
		width: 100%;
	}

	.browserSnapshot {
		padding: 0 50px;
		background-color: #ececec;
	}

	.browserSnapshot:first-of-type {
		padding-top: 9%;
		background-color: #ececec;
	}

	.browserSnapshot:last-of-type {
		padding-top: 30px;
		padding-bottom: 75px;
		background-color: #ececec;
	}
}

// plantMidwest section
#plantMidwest > div:last-of-type {
	padding: 9%;
	 background-color: #ececec;
}

#plantMidwest {
	.browserSnapshot:last-of-type {
		padding-top: 9%;
		padding-bottom: 75px;
		background-color: #ececec;
	}
}

// stcCrossfit section
#stcCrossfit {
}

// ekklesiaStl section
#ekklesiaStl {
}
