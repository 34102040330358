#devSection {
	img {
		width: 100%;
	}

	p {
		margin: 30px 0;
	}

	#dartfeed p {
		margin: 6% 0;
	}

	.browserSnapshot {
		padding: 0 50px;
		background-color: #ececec;
	}

	.browserSnapshot:first-of-type {
		padding-top: 75px;
		margin-top: 50px;
		background-color: #ececec;
	}

	.browserSnapshot:last-of-type {
		padding-top: 30px;
		padding-bottom: 75px;
		background-color: #ececec;
	}
}
